import React from 'react';
import styled from 'styled-components';

import {
  Margin,
  ContainerNavigation,
  ContainerFooter,
  ContainerTinySectionDivider,
  ContainerTinyFooterDivider,
} from '_templates/Pages';
import colours from '_theming/colours';
import Curved from '_components/Curved';
import Footer from '_sections/Footer';
import Layout from '_components/Layout';
import NavigationBar from '_components/NavigationBar';
import NotFound from '_sections/NotFound';

/**
 * Page container.
 */
const ContainerPage = styled.main`
  display: flex;
  flex-flow: column nowrap;

  justify-content: space-between;

  min-height: 100vh;
`;

/**
 * Container for each section.
 */
const ContainerSection = styled.div`
  background-color: ${(props) => props.bgColor || colours.white};
`;

/**
 * Privacy Policy page.
 *
 * @returns {React.Component} Privacy Policy page.
 */
const NotFoundPage = () => (
  <Layout>
    <title>Not Found - Pricematepay</title>

    <ContainerPage>
      <div>
        <ContainerNavigation>
          <Margin>
            <NavigationBar />
          </Margin>
        </ContainerNavigation>

        <ContainerTinySectionDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerTinySectionDivider>
      </div>

      <ContainerSection bgColor={colours.white}>
        <Margin>
          <NotFound />
        </Margin>
      </ContainerSection>

      <div>
        <ContainerTinyFooterDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerTinyFooterDivider>

        <ContainerFooter bgColor={colours.daintree}>
          <Margin>
            <Footer />
          </Margin>
        </ContainerFooter>
      </div>
    </ContainerPage>
  </Layout>
);

export default NotFoundPage;
