import React from 'react';
import styled from 'styled-components';

import breakpoints from '_util/responsiveDesign/breakpoints';
import colours from '_theming/colours';
import InternalLinkButton from '_components/InternalLinkButton';

// Extract responsive design variables.
const { mobile, tablet } = breakpoints;

/**
 * Component container.
 */
const Container = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  text-align: center;

  @media screen and (max-device-width: ${mobile.max}px) {
    font-size: 1.25rem;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    font-size: 2rem;
  }

  padding: 0 20px;
`;

/**
 * Section heading.
 */
const H1 = styled.h1`
  font-family: 'Big Caslon Medium', serif;

  @media screen and (max-device-width: ${mobile.max}px) {
    font-size: 6rem;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    font-size: 8rem;
  }

  margin: 0;
`;

/**
 * Internal link button.
 */
const CustomInternalLinkButton = styled(InternalLinkButton)`
  @media screen and (max-device-width: ${mobile.max}px) {
    padding: 10px 25px;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    padding: 20px 35px;
  }
`;

/**
 * The Not Found" section.
 *
 * @returns {React.Component} Section.
 */
const NotFound = () => (
  <Container>
    <H1>404</H1>

    <p>We couldn't find what you're looking for.</p>

    <p>Click the button below to go back to the homepage.</p>

    <p>
      <CustomInternalLinkButton to="/" bgcolor={colours.redDamask}>
        Go back Home
      </CustomInternalLinkButton>
    </p>
  </Container>
);

export default NotFound;
